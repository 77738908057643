export default [
  {
    name: 'My Account',
    icon: 'UserIcon',
    to: '/members/view',
    protected: true,
  },
  {
    name: 'Purchase Tickets',
    icon: 'TicketIcon',
    to: '/tickets',
  },
  {
    name: 'Payments',
    icon: 'CreditCardIcon',
    to: '/payments',
  },
  {
    name: 'Manage Company',
    icon: 'AccountGroupIcon',
    to: '/companies/manager/dashboard',
    protected: true,
    companyManagerOnly: true,
  },
  {
    name: 'Membership Card',
    icon: 'CardAccountDetailsIcon',
    to: '/memberships/card',
    protected: true,
  },
  {
    name: 'Apply To Vote',
    icon: 'VoteIcon',
    to: '/vote',
    protected: true,
  },
  {
    name: 'Judging Application',
    icon: 'AccountCheckIcon',
    to: '/judges/apply',
    protected: true,
  },
]
