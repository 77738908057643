export default [
  {
    name: 'Dashboard',
    icon: 'CalendarIcon',
    to: '/admin/dashboard',
  },
  {
    name: 'My Account',
    icon: 'UserIcon',
    to: '/members/view',
  },
  {
    name: 'Members',
    icon: 'UsersIcon',
    to: '/admin/members',
  },
  // {
  //   name: 'CSV Import',
  //   icon: 'UploadIcon',
  //   to: '/admin/csv-files/import',
  // },
  {
    name: 'Companies',
    icon: 'FlagIcon',
    to: '/admin/companies',
  },
  {
    name: 'Events',
    icon: 'CalendarIcon',
    to: '/admin/events',
  },
  {
    name: 'Finances',
    icon: 'DollarSignIcon',
    to: '/admin/payments',
  },
  {
    name: 'Tickets',
    icon: 'WindowIcon',
    to: '/admin/tickets',
  },
  {
    name: 'Judges',
    icon: 'ListIcon',
    to: '/admin/judges',
  },
  {
    name: 'Promo Codes',
    icon: 'UploadIcon',
    to: '/admin/promo-code',
  },
  {
    name: 'Membership Statistics',
    icon: 'CalendarIcon',
    to: '/admin/memberships/statistics',
  },
  {
    name: 'Voters',
    icon: 'ListIcon',
    to: '/admin/voter-qualifications',
  },
  {
    name: 'Admin Log',
    icon: 'ListIcon',
    to: '/admin/logs',
  },
  {
    name: 'Configs',
    icon: 'ListIcon',
    to: '/admin/configs',
  },
]
